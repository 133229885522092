import React from 'react';
import Link from 'next/link';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Section1Wrapper } from '../../pagestyles/components/new-homepage/Section1Wrapper';
import { sendGa4Event } from '../../common/GTM';
import { QUIZ_BTN_PRIMARY_TEXT } from '../../common/const';
import Button from '../common/button/Button';

interface Props {
  surveyPageRouter: (event: string) => void;
  startParkPrice: string;
}

const Section1: React.FC<Props> = ({
  surveyPageRouter,
  startParkPrice,
}: Props) => {
  const scrollToView = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      // const topPos = element.offsetTop;
      // element.scrollTop = topPos - 100;
      let positionElement = element.offsetTop;
      if (id === 'overview') {
        positionElement += 850;
      }
      if (id === 'section05') {
        positionElement += 750;
      }
      window.scrollTo(0, positionElement);
      // element.scrollIntoView({ behavior: "smooth"});
    }
  };
  return (
    <Section1Wrapper>
      <div className="homepage-hero hero">
        <LazyLoadImage
          className="desktop"
          src="/assets/images/homepage/new_landing_page_hero.jpg"
        />
        <LazyLoadImage
          className="mobile"
          src="/assets/images/homepage/new_landing_page_hero_m.jpg"
        />
        <div className="hero-content text-center">
          <h2 className="homepage-logo">
            Personalised vitamins. <br />
            Simplify your health  <br className="mobile" /> and wellbeing
          </h2>
          <p className="desktop">
            Take our quiz to get a daily vitamin pack tailored to your
            goals, lifestyle and diet. Starting at less than{' '}
            <span className="startPrice">{startParkPrice}</span>/day.&nbsp;
            Monthly deliveries. Adjust or cancel anytime.
          </p>
          <p className="mobile">
            Take our quiz to get a daily vitamin pack tailored to your
            goals, lifestyle and diet. Starting at less than{' '}
            <span className="startPrice">{startParkPrice}</span>/day.&nbsp;
          </p>
          <div className="trustpilot-box">
            <div
              className="trustpilot-widget"
              id="trustbox"
              data-locale="en-US"
              data-template-id="5419b732fbfb950b10de65e5"
              data-businessunit-id="5d522e49f3ce9400013574b3"
              data-style-height="24px"
              data-style-width="100%"
              data-theme="light"
              data-tags="SelectedReview"
            >
              <a
                href="https://www.trustpilot.com/review/vitable.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                Trustpilot
              </a>
            </div>
            <p>Based on <span>800 + reviews</span></p>
          </div>
          <div className="main-cta">
            <Button dataTestId="takeQuiz" onClick={() => surveyPageRouter('click-take-quiz')}>{QUIZ_BTN_PRIMARY_TEXT}</Button>
          </div>
          <Link href="/products">
            <Button type='links' href='/products' className='browse-library' onClick={() => sendGa4Event('browseLibrary', 'click', 'heroBanner')}>
              Browse Library
            </Button>
          </Link>
        </div>
        <div className="discover" id="discover">
          <button type="button" className="go-down" onClick={() => scrollToView('section2')} aria-label='arrow-down' />
        </div>
      </div>
    </Section1Wrapper>
  )
};
export default Section1;
