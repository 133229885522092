import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Section2Wrapper } from '../../pagestyles/components/new-homepage/Section2Wrapper';

const Section2: React.FC = () => (
  <Section2Wrapper id='section2'>
    <div className='advantage d-flex justify-content-center'>
      <p>
        <LazyLoadImage src="/assets/images/homepage/section6/Made-in-Australia.svg" />
        Proudly Australian owned
      </p>
      <p>
        <LazyLoadImage src="/assets/images/homepage/Personalised-Vitamins.svg" />
        Plastic-free daily sachets 
      </p>
      <p>
        <LazyLoadImage src="/assets/images/homepage/section6/Premium-Ingredients.svg" />
        Premium ingredients
      </p>
      <p>
        <LazyLoadImage src="/assets/images/homepage/Free-expert-guidance.svg" />
        Free expert guidance
      </p>
    </div>
    <div className='title'>
      <div className='left'>
        <h2>Unlock your <span>personalised</span> <br /> vitamin plan: From 1.2 million  <br /> combinations</h2>
        <p>We don’t believe in one-size-fits-all products - with <br /> Vitable,  you&apos;ll receive daily packs <span>personalised to your <br /> unique health needs</span> (from over 1.2 million possible <br /> vitamin combinations).</p>
      </div>
      <div className='right d-flex justify-content-end'>
        <LazyLoadImage src="/assets/images/homepage/Frame-104.png" />
      </div>
    </div>
  </Section2Wrapper>
);
export default Section2;
