import React, { useEffect } from 'react';

const TraceBadge = (): JSX.Element => {
  const traceBadgeId = 'trace-badge-id';
  const traceBadgeUrl = 'https://public.our-trace.com/scripts/trace-badge.js';
  const traceBadgeElement = "<trace-badge instance-id='989952a9-e07f-488e-9482-372268e3ca86'></trace-badge>";

  useEffect(() => {
    const script = document.createElement('script');
    script.src = traceBadgeUrl;
    document.body.appendChild(script);
    const cacbonOffsetImg = document.getElementById(traceBadgeId);
    if (cacbonOffsetImg) {
      cacbonOffsetImg.innerHTML = traceBadgeElement;
    }
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id={traceBadgeId} />;
};

export default TraceBadge;
