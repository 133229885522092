import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Section3Wrapper } from '../../pagestyles/components/new-homepage/Section3Wrapper';

const Section3: React.FC = () => (
  <Section3Wrapper>
    <div className='vector' />
    <h2>
      So, how does it work?
      <img src="/assets/images/homepage/Simple-v1.png" alt="" />
      {/* <span>it&apos;s Simple</span> */}
    </h2>
    <div className='steps d-flex'>
      <div className='step'>
        <LazyLoadImage className='step-img' src="/assets/images/homepage/Step-1.png" />
        <div className='step-content'>
          <h3>
            <LazyLoadImage src="/assets/images/homepage/1.png" />
            Take the expert quiz
          </h3>
          <p>Tell us about your diet, lifestyle, and health goals so we can tailor a custom vitamin plan to your needs</p>
        </div>
      </div>
      <div className='step'>
        <LazyLoadImage className='step-img' src="/assets/images/homepage/Step-2.png" />
        <div className='step-content'>
          <h3>
            <LazyLoadImage src="/assets/images/homepage/2.png" />
            Get your personalised recommendation
          </h3>
          <p>Based on your quiz results, you&apos;ll receive your personalised vitamin recommendations out of 1.2 million possible pack combinations</p>
        </div>
      </div>
      <div className='step'>
        <LazyLoadImage className='step-img' src="/assets/images/homepage/Step-3.png" />
        <div className='step-content'>
          <h3>
            <LazyLoadImage src="/assets/images/homepage/3.png" />
            Start your <br /> new routine
          </h3>
          <p>Based on your chosen plan and delivery frequency, we&apos;ll deliver your plastic-free daily vitamin sachets, leaving you more time focus on your health goals - go you!</p>
        </div>
      </div>
    </div>
  </Section3Wrapper>
);
export default Section3;
