import React from 'react';
import { Section7Wrapper } from '../../pagestyles/components/new-homepage/Section7Wrapper';
import { TraceBadge } from '../common/labels';

const Section7: React.FC = () => (
  <Section7Wrapper>
    <div className='container'>
      <div className='left'>
        <div className='plastic-free-typo'> </div>
        <div className='plastic-image'> </div>
      </div>
      <div className='right'>
        <div className='title'>We are passionate about sustainability</div>

        <div className='description'>
          Our mission is to shake up the throwaway culture of vitamins. Through our carbon measurement partnership with Trace and our combination of
          <strong> home-compostable daily sachets</strong> and recyclable FSC-certified packaging to ensure minimal waste, we are committed to limiting our environmental impact,
          without compromising on the quality and safety of our products. It&lsquo;s time to cut the clutter and swap out all those plastic bottles for a morning vitamin routine
          you can feel proud of.
        </div>

        <a href='/sustainability'>Learn more</a>

        <div className='logo-list'>
          <TraceBadge />
          <img src='/assets/images/homepage/section7/Sustainable2.png' alt='' />
          <img src='/assets/images/homepage/section7/OkCompost.png' alt='' />
          <img src='/assets/images/homepage/section7/Sustainable1.png' alt='' />
        </div>
      </div>
    </div>
    <div className='bottom'> </div>
  </Section7Wrapper>
);
export default Section7;
