import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Section9Wrapper } from '../../pagestyles/components/new-homepage/Section9Wrapper';
import { useLaunchDarkly } from '../../containers/LaunchDarklyContainer';
import Button from '../common/button/Button';

const Section9: React.FC = () => {
  const trackEvent = useLaunchDarkly((container) => container.trackEvent);
  const router = useRouter();
  const goToQuiz = async () => {
    await trackEvent('take-the-expert-quiz');
    router.push('/survey');
  };
  const [title, setTitle] = useState(<>Ready to take the next step?</>);
  const handleResize = () => {
    if (window.innerWidth > 1000) {
      setTitle(<>Ready to take the next step?</>);
    } else {
      setTitle(
        <>
          Ready to <br /> take the next step?
        </>,
      );
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Section9Wrapper>
      <div className='container'>
        <div className='typo' />
        <div className='title'>{title}</div>
        <div className='take-quiz'>
          <Button onClick={goToQuiz} aria-hidden>
            Take the expert quiz
          </Button>
          <div className='arrow-icon' />
        </div>
        <div className='main-image' />
      </div>
    </Section9Wrapper>
  );
};
export default Section9;
