import React, { useState } from 'react';

const CloseIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
    <path
      d='M5.18333 12.6667L4.25 11.7333L7.98333 8.00001L4.25 4.26668L5.18333 3.33334L8.91667 7.06668L12.65 3.33334L13.5833 4.26668L9.85 8.00001L13.5833 11.7333L12.65 12.6667L8.91667 8.93334L5.18333 12.6667Z'
      fill='#BFAE8A'
    />
  </svg>
);

type BottomPopupProps = {
  children: React.ReactNode;
};

const BottomPopup = ({ children }: BottomPopupProps): JSX.Element => {
  const [show, setShow] = useState(true);

  const onClose = () => {
    setShow(false);
  };

  return (
    <div className='wrapper' style={{ display: show ? 'flex' : 'none' }}>
      <div className='image'>
        <img src='assets/images/popup/vitable-image.png' alt='' />
      </div>
      <div className='content'>{children}</div>
      <div className='close-icon' onClick={onClose} aria-hidden>
        {CloseIcon}
      </div>
    </div>
  );
};

export default BottomPopup;
