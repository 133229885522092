/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { solaire } from '../../../../styles/theme';

export const Section9Wrapper = styled.section`
  background-image: url('/assets/images/homepage/section9/Background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff;
  background-position: center 135px;
  .typo {
    margin: 0 auto;
    padding: 110px 0 97px;
    content: url('/assets/images/homepage/section9/Typo.svg');
  }

  .title {
    color: #000;
    text-align: center;
    font-size: 48px;
    font-family: ${solaire};
  }

  .take-quiz {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 41px 0 90px;
  }

  .take-quiz-btn {
    display: inline-flex;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: var(--accent-watermelon, #f5785a);
    cursor: pointer;
    margin: 0 auto;
    color: #fff;
    font-size: 18px;
    font-family: Neue Haas Grotesk;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.36px;
  }

  .arrow-icon {
    position: absolute;
    top: -50px;
    left: calc(50% + 158px);
    content: url('/assets/images/homepage/section9/ArrowIcon.svg');
  }

  .main-image {
    width: 322.331px;
    margin: 0 auto;
    content: url('/assets/images/homepage/section9/MainImage.png');
  }

  @media (max-width: 1000px) {
    background-image: url('/assets/images/homepage/section9/BackgroundMobile.png');
    background-position: 0% 90px;

    .typo {
			transform: scale(1.2, 1.2);
			-ms-transform: scale(1.2, 1.2);
			-webkit-transform: scale(1.2, 1.2);
      content: url('/assets/images/homepage/section9/TypoMobile.svg');
    }

    .title {
      font-size: 32px;
      padding: 0 32px;
    }

    .arrow-icon {
      display: none;
    }

    .take-quiz {
      margin: 30px 0 74px;
    }

    .take-quiz-btn {
      font-size: 16px;
    }

    .main-image {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;
