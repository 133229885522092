import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Section6Wrapper } from '../../../pagestyles/components/new-homepage/Section6Wrapper';
import data from './data.json';

const BlackPlusIcon = (
  <svg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='27' cy='27' r='26.5' fill='#343434' stroke='white' />
    <path
      d='M26.2969 18.5625H27.7031C27.8281 18.5625 27.8906 18.625 27.8906 18.75V35.25C27.8906 35.375 27.8281 35.4375 27.7031 35.4375H26.2969C26.1719 35.4375 26.1094 35.375 26.1094 35.25V18.75C26.1094 18.625 26.1719 18.5625 26.2969 18.5625Z'
      fill='#FAF9F6'
    />
    <path
      d='M19.125 26.1094H34.875C35 26.1094 35.0625 26.1719 35.0625 26.2969V27.7031C35.0625 27.8281 35 27.8906 34.875 27.8906H19.125C19 27.8906 18.9375 27.8281 18.9375 27.7031V26.2969C18.9375 26.1719 19 26.1094 19.125 26.1094Z'
      fill='#FAF9F6'
    />
  </svg>
);

const WhitePlusIcon = (
  <svg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='27' cy='27' r='26.5' fill='white' stroke='#CACACA' />
    <path
      d='M26.2969 18.5625H27.7031C27.8281 18.5625 27.8906 18.625 27.8906 18.75V35.25C27.8906 35.375 27.8281 35.4375 27.7031 35.4375H26.2969C26.1719 35.4375 26.1094 35.375 26.1094 35.25V18.75C26.1094 18.625 26.1719 18.5625 26.2969 18.5625Z'
      fill='#272626'
    />
    <path
      d='M19.125 26.1094H34.875C35 26.1094 35.0625 26.1719 35.0625 26.2969V27.7031C35.0625 27.8281 35 27.8906 34.875 27.8906H19.125C19 27.8906 18.9375 27.8281 18.9375 27.7031V26.2969C18.9375 26.1719 19 26.1094 19.125 26.1094Z'
      fill='#272626'
    />
  </svg>
);

const ArrowIcon = (
  <svg width='39' height='38' viewBox='0 0 39 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_175_4184)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.70398 34.9067C19.1799 33.0711 28.1796 18.202 30.6791 2.42735C30.7225 2.15649 30.9836 1.97014 31.262 2.01297C31.5405 2.05579 31.7325 2.31014 31.689 2.581C29.1205 18.7976 19.7805 34.0083 1.81493 35.8951C1.53511 35.925 1.28297 35.7275 1.25168 35.4539C1.22034 35.1816 1.42287 34.9366 1.70398 34.9067Z'
        fill='#272626'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.1374 2.80043C30.582 3.48573 29.8947 4.35328 29.8138 4.44951C27.6203 7.05738 25.1278 9.19355 22.2276 11.0486C21.9923 11.1995 21.6747 11.1351 21.5192 10.9059C21.3637 10.6768 21.4292 10.3678 21.6644 10.217C24.4774 8.41897 26.8952 6.34789 29.0227 3.81795C29.1468 3.67057 30.6905 1.7194 31.0331 1.34774C31.1729 1.19465 31.301 1.1504 31.3322 1.14276C31.4965 1.09603 31.623 1.13203 31.7142 1.17806C31.8168 1.23077 31.9644 1.35793 32.0331 1.59648C32.104 1.84641 32.12 2.39694 32.1356 2.53561C32.3765 4.62734 32.4582 6.98194 32.9883 9.142C33.4936 11.202 34.408 13.0873 36.3324 14.3454C36.5668 14.4983 36.629 14.8082 36.4723 15.036C36.3156 15.2638 35.9973 15.3237 35.7628 15.1708C33.6116 13.7638 32.5589 11.6737 31.994 9.37177C31.4673 7.22439 31.3694 4.89058 31.1374 2.80043Z'
        fill='#272626'
      />
    </g>
    <defs>
      <clipPath id='clip0_175_4184'>
        <rect width='36' height='37' fill='white' transform='matrix(-0.0365404 0.999332 0.999332 0.0365404 1.31543 0)' />
      </clipPath>
    </defs>
  </svg>
);

const PrevIcon = (active: boolean) => (
  <svg width='26' height='17' viewBox='0 0 26 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path id='Vector 54' d='M26 8.5L1 8.5M1 8.5C3.66667 8.5 9 10.1 9 16.5M1 8.5C3.66667 8.16667 9 6.1 9 0.500001' stroke={active ? '#000' : '#C6C6C6'} strokeWidth='2' />
  </svg>
);

const NextIcon = (active: boolean) => (
  <svg width='26' height='17' viewBox='0 0 26 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path id='Vector 53' d='M0 8.5H25M25 8.5C22.3333 8.5 17 6.9 17 0.5M25 8.5C22.3333 8.83333 17 10.9 17 16.5' stroke={active ? '#000' : '#C6C6C6'} strokeWidth='2' />
  </svg>
);

const Section6: React.FC = () => {
  const [currentProduct, setCurrentProduct] = useState(0);
  const [relevantInfoNumber, setRelevantInfoNumber] = useState(1);

  const renderRelevantInformation = () => {
    const current = data[currentProduct].contents[relevantInfoNumber - 1];
    if (current) {
      return (
        <div className={`relevant-info pos-${relevantInfoNumber}`}>
          <div className='sub-title'>{current.title}</div>
          <div className='sub-content'>{current.content}</div>
        </div>
      );
    }
    return <></>;
  };

  const handleRelevantInfoNumber = (number: number) => {
    setRelevantInfoNumber(number);
  };

  const onClickPrev = () => {
    if (currentProduct > 0) setCurrentProduct(currentProduct - 1);
  };

  const onClickNext = () => {
    if (currentProduct < data.length - 1) setCurrentProduct(currentProduct + 1);
  };

  const renderPagination = () => {
    const total = data.length;
    const productName = data[currentProduct].name;
    return (
      <div className='pagination'>
        <div className='product-name'>{productName}</div>
        <div className='operation'>
          <div className='prev-icon' onClick={onClickPrev} aria-hidden>
            {PrevIcon(currentProduct > 0)}
          </div>
          <div className='page-number'>
            <span className='active-number'>{currentProduct + 1}</span>
            <span className='vertical-line'>-</span>
            <span className='total-number'>{total}</span>
          </div>
          <div className='next-icon' onClick={onClickNext} aria-hidden>
            {NextIcon(currentProduct < total - 1)}
          </div>
        </div>
      </div>
    );
  };

  const renderProductActions = () => (
    <div className='product-frame'>
      <div className='position-1' onClick={() => handleRelevantInfoNumber(1)} aria-hidden>
        {relevantInfoNumber === 1 ? BlackPlusIcon : WhitePlusIcon}
      </div>
      <div className='arrow-1' style={{ display: relevantInfoNumber === 1 ? 'block' : 'none' }}>
        {ArrowIcon}
      </div>
      <div className='position-2' onClick={() => handleRelevantInfoNumber(2)} aria-hidden>
        {relevantInfoNumber === 2 ? BlackPlusIcon : WhitePlusIcon}
      </div>
      <div className='arrow-2' style={{ display: relevantInfoNumber === 2 ? 'block' : 'none' }}>
        {ArrowIcon}
      </div>
      <div className='position-3' onClick={() => handleRelevantInfoNumber(3)} aria-hidden>
        {relevantInfoNumber === 3 ? BlackPlusIcon : WhitePlusIcon}
      </div>
      <div className='arrow-3' style={{ display: relevantInfoNumber === 3 ? 'block' : 'none' }}>
        {ArrowIcon}
      </div>
    </div>
  );

  const caculateCurrentProduct = (index: number) => {
    if (index < currentProduct - 2) {
      return 'hidden-product-0';
    }
    if (index === currentProduct - 2 || (currentProduct - 2 < 0 && index === data.length + (currentProduct - 2))) {
      return 'hidden-product-1';
    }

    if (index === currentProduct - 1 || (currentProduct - 1 < 0 && index === data.length + (currentProduct - 1))) {
      return 'hidden-product-2';
    }

    if (index === currentProduct) {
      return 'current-product';
    }

    if (index === currentProduct + 1 || (data.length < currentProduct + 1 && index === currentProduct + 1 - data.length)) {
      return 'hidden-product-3';
    }

    if (index > currentProduct + 1) {
      return 'hidden-product-last';
    }
    return '';
  };

  const renderProducts = () =>
    data.map((item, index) => (
      <div key={item.id} className={`product-bg ${caculateCurrentProduct(index)}`}>
        <LazyLoadImage src={item.img} alt='' />
      </div>
    ));

  return (
    <Section6Wrapper>
      {renderProducts()}
      <div className='container'>
        <div className='left'>
          <div className='title'>
            Premium supplements formulated with
            <span className='highlight'>you</span>in mind
          </div>

          <div className='content'>
            <p>
              You body deserves only the best ingredients. That&apos;s why we work closely with expert formulators to bring you{' '}
              <strong>high-quality vitamins and supplements</strong>, free from nasties, in the forms your body is best able to absorb. All of our products are{' '}
              <strong>formulated and made</strong> to the highest quality standards using the best ingredients sourced from around the world.
            </p>

            <a href='/about'>Learn more</a>
          </div>
          <div className='list-item'>
            <div className='item'>
              <div className='science-icon' />
              <p>Formulated by experts</p>
            </div>
            <div className='item'>
              <div className='madeinaustralia-icon' />
              <p>Proudly Australian owned</p>
            </div>
            <div className='item'>
              <div className='premiumingredients-icon' />
              <p>Premium ingredients</p>
            </div>
            <div className='item'>
              <div className='nonGMO-icon' />
              <p>No nasties</p>
            </div>
          </div>
        </div>

        <div className='right'>
          <div className='product'>
            {renderProductActions()}
            {renderPagination()}
          </div>
          {renderRelevantInformation()}
        </div>
      </div>
    </Section6Wrapper>
  );
};
export default Section6;
