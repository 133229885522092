import React, { useMemo } from 'react';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './section6/Section6';
import Section7 from './Section7';
import Section8 from './Section8';
import Section9 from './Section9';
import { NewHomepageWrapper } from '../../pagestyles/components/new-homepage/NewHomepageWrapper';

interface Props {
  surveyPageRouter: (event: string) => void;
  startParkPrice: string;
}

const NewHomepage: React.FC<Props> = ({ surveyPageRouter, startParkPrice }: Props) => {
  const section1 = useMemo(() => <Section1 surveyPageRouter={surveyPageRouter} startParkPrice={startParkPrice} />, [surveyPageRouter, startParkPrice]);
  const section2 = useMemo(() => <Section2 />, []);
  const section3 = useMemo(() => <Section3 />, []);
  const section4 = useMemo(() => <Section4 />, []);
  const section5 = useMemo(() => <Section5 />, []);
  const section6 = useMemo(() => <Section6 />, []);
  const section7 = useMemo(() => <Section7 />, []);
  const section8 = useMemo(() => <Section8 />, []);
  const section9 = useMemo(() => <Section9 />, []);
  return (
    <NewHomepageWrapper>
      {section1}
      {section2}
      {section3}
      {section4}
      {section5}
      {section6}
      {section7}
      {section8}
      {section9}
    </NewHomepageWrapper>
  );
};
export default NewHomepage;
