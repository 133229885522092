/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { grotesk, black } from '../../../../styles/theme';

export const Section4Wrapper = styled.section`
  background-color: #FDFCFA;
  padding-bottom: 202px;
  h2 {
    position: relative;
    text-align: center;
    max-width: 446px;
    margin: auto;
    margin-bottom: 46px;
    &:after {
      content: '';
      position: absolute;
      background-image: url(/assets/images/homepage/Sparkle.png);
      background-repeat: no-repeat;
      background-size: cover;
      width: 52px;
      height: 50px;
      top: -22px;
      right: -42px;
    }
  }
  .trustpilot-widget {
    margin-bottom: 100px;
  }
  .trustpilot-reviews {
    padding-left: 172px;
    position: relative;
    margin-bottom: 157px;
  }
  .slick-list {
    padding:0 0 0 14.4% !important;
  }
  .slider-item {
    padding: 52px;
    border-radius: 25px;
    border: 1px solid #EAEEFB;
    background: #FFF;
    // box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.03);
    // box-shadow: rgba(0, 0, 0, 0.03) 0 0 25px;
    max-width: 344px;
    margin: 0 24px 0 0;
    img {
      display: block;
      margin-bottom: 32px;
    }
    h3, p, strong {
      color: #000;
      font-size: 18px;
      font-family: ${grotesk};
      letter-spacing: 0.36px;
    }
    h3 {
      font-weight: 500;
      line-height: 25.2px;
      margin-bottom: 32px;
    }
    p {
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 32px;
    }
    strong {
      font-weight: 500;
    }
  }
  h4 {
    text-align: center;
    font-size: 18px;
    font-family: ${grotesk};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-bottom: 50px;
  }
  .partners {
    display: flex;
    justify-content: center;
    padding-bottom: 140px;
    background-image: url(/assets/images/homepage/Vector-section4.png);
    background-repeat: no-repeat;
    background-position: 49% 100%;
    p {
      position: relative;
      padding: 0 26px;
      margin: auto 0;
      img {
        width: auto;
        height: auto;
        object-fit: contain;
      }
      img.vogue {
        width: 109px;
      }
      img.urban {
        width: 126px;
        height: 50px;
      }
      img.body-soul {
        width: 162px;
      }
      img.sydney-morning-herald {
        width: 124px;
      }
      img.insider-logo {
        width: 161px;
      }
      &:after {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: ${black};
        right: -3px;
        top: 46%;
      }
      &:last-child {
        &:after {
          content: unset;
        }
      }
    }
  }
  @media only screen and (max-width: 1440px) {
    .slick-list {
      padding: 0 0 0 14.4% !important;
    }
  }
  @media only screen and (max-width: 1400px) {
    .slick-list {
      padding: 0 0 0 3.4% !important;
    }
  }
  @media only screen and (max-width: 1260px) {
    .slick-list {
      padding: 0 0 0 23.4% !important;
    }
  }
  @media only screen and (max-width: 1024px) {
    .slick-list {
      padding: 0 0 0 13.5% !important;
    }
  }
  @media only screen and (max-width: 992px) {
    padding-bottom: 131px;
    .slick-list {
      padding: 0 0 0 0% !important;
    }
    .trustpilot-reviews {
      margin-bottom: 95px;
    }
    h2 {
      max-width: 306px;
      &:after {
        top: -8px;
        right: -16px;
        width: 28px;
        height: 28px;
      }
    }
    h4 {
      display: none !important;
    }
    .partners {
      flex-direction: column;
      align-items: center;
      background-size: 171px;
      padding-bottom: 60px;
      p {
        padding: 26px 0;
        &:after {
          top: unset;
          bottom: -3px;
          right: 46%;
        }
      }
    }
  }
  @media only screen and (max-width: 834px) {
    .slick-list {
      padding: 0px 0 0 42% !important;
    }
  }
  @media only screen and (max-width: 768px) {
    background-image: url(/assets/images/homepage/Vector-V.png);
    background-repeat: no-repeat;
    background-position: 0 35%;
    background-size: 100% 668px;
    h2 {
      margin-bottom: 50px;
    }
    .trustpilot-widget {
      margin-bottom: 60px;
    }
    .trustpilot-reviews {
      padding-left: 65px;
      // margin-bottom: 95px;
      position: relative;
    }
    .slick-list {
      padding: 0 0 0 9.1% !important;
    }
    .slider-item {
      padding: 36px 32px;
      margin: 0 17px 0 0;
      max-width: 290px;
      img, h3, p {
        margin-bottom: 24px;
      }
      img {
        width: 77px;
      }
      h3 {
        font-size: 16px;
      }
      p, strong {
        font-size: 14px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .slick-list {
      padding: 0 0 0 38% !important;
    }
  }
  @media only screen and (max-width: 450px) {
    h2 {
      max-width: 306px;
    }
    .slick-list {
      padding:0 0 0 23.1% !important;
    }
    .slider-item {
      max-width: 244px;
    }
  }
  @media only screen and (max-width: 375px) {
    .slick-list {
      padding:0 0 0 17% !important;
    }
  }
  @media only screen and (max-width: 360px) {
    .trustpilot-reviews {
      padding-left: 30px;
      padding-right: 30px;
    }
    .slick-list {
      padding:0 0 0 0 !important;
    }
    .slider-item {
      max-width: unset;
      margin: 0;
    }
  }
`;

export const Buttons = styled.div`
  button {
    padding: 0;
    position: absolute;
    border: none;
    border-radius: 50%;
    width: 57px;
    height: 57px;
    background-repeat: no-repeat;
    background-position: 50%;
    &.prev, &.next {
      top: 30%;
    }
    &.prev {
      background-color: #BDBCBA;
      background-image: url(/assets/images/homepage/bx_chevron-left.png);
      left: 68px;
    }
    &.next {
      background-image: url(/assets/images/homepage/bx_chevron-right.png);
      background-color: ${black};
      right: 68px;
    }
  }
  @media only screen and (max-width: 769px) {
    button {
      width: 28px;
      height: 28px;
      &.prev, &.next {
        top: 20%;
      }
      &.prev {
        background-image: url(/assets/images/homepage/bx_chevron-left-m.png);
        left: 27px;
      }
      &.next {
        background-image: url(/assets/images/homepage/bx_chevron-right-m.png);
        right: 27px;
      }
    }
  }
  @media only screen and (max-width: 360px) {
    button {
      &.prev, &.next {
        top: 30%;
      }
      &.prev {
        left: 8px;
      }
      &.next {
        right: 8px;
      }
    }
  }
`