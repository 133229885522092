import React, { useEffect, useRef, useState } from 'react';
import { Section8Wrapper } from '../../pagestyles/components/new-homepage/Section8Wrapper';

const DOWNLOAD_APP_STORE_LINK = 'https://apps.apple.com/au/app/vitable/id1552666276';
const DOWNLOAD_GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.vitable&hl=en_AU&gl=US';

const TrackList = [
  {
    id: 1,
    icon: 'calender-icon',
    text: 'Track your vitamin routine – never miss a day!',
    img: '/assets/images/homepage/section8/Screen1_Track.png',
  },
  {
    id: 2,
    icon: 'star-icon',
    text: 'Earn rewards and stay motivated ',
    img: '/assets/images/homepage/section8/Screen2_Rewards.png',
  },
  {
    id: 3,
    icon: 'graph-icon',
    text: 'Monitor your results in real-time – track your sleep and energy levels',
    img: '/assets/images/homepage/section8/Screen3_Monitor.png',
  },
  {
    id: 4,
    icon: 'edit-icon',
    text: 'Edit your daily packs anytime to suit your changing needs',
    img: '/assets/images/homepage/section8/Screen4_EditPack.png',
  },
  {
    id: 5,
    icon: 'truck-icon',
    text: 'Manage your delivery – pause, delay or track your order ',
    img: '/assets/images/homepage/section8/Screen5_Manage.png',
  },
];

const Section8: React.FC = () => {
  const [screen, setScreen] = useState<number>(1);
  const scrollListRef = useRef<HTMLInputElement | null>(null);
  const [preventScroll, setPreventScroll] = useState<boolean>(false);
  const DEFAULT_TIME_AUTO_PLAY = 4000;
  const TIME_AUTO_PLAY = 30000;
  const [timeAutoPlay, setTimeAutoPlay] = useState<number>(DEFAULT_TIME_AUTO_PLAY);
  const [touchStart, setTouchStart] = useState<number>(0);
  const [touchEnd, setTouchEnd] = useState<number>(0);
  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const handleSwipe = (isLeftSwipe: boolean, isRightSwipe: boolean) => {
    if (isRightSwipe || isLeftSwipe) {
      setTimeAutoPlay(TIME_AUTO_PLAY);
      setPreventScroll(true);
    }

    if (isLeftSwipe) {
      if (screen === TrackList.length) {
        setScreen(1);
      } else {
        setScreen(screen + 1);
      }
    }
    if (isRightSwipe) {
      if (screen === 1) {
        setScreen(TrackList.length);
      } else {
        setScreen(screen - 1);
      }
    }
  };

  const onTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(0); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: React.TouchEvent) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    handleSwipe(isLeftSwipe, isRightSwipe);
  };

  const ourRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
  });

  const onMouseDown = (e: React.MouseEvent) => {
    if (!ourRef.current) return;
    setIsMouseDown(true);
    const startX = e.pageX;
    mouseCoords.current = { startX };
    setTouchStart(0);
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const x = e.pageX;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    setTouchEnd(walkX);
  };

  const onMouseUp = () => {
    if (!ourRef.current) return;
    setIsMouseDown(false);
    const distance = touchEnd - touchStart;
    const isRightSwipe = distance > 0 && distance > minSwipeDistance;
    const isLeftSwipe = distance < 0 && distance < -minSwipeDistance;
    handleSwipe(isLeftSwipe, isRightSwipe);
  };

  useEffect(() => {
    const action = setInterval(() => {
      setPreventScroll(true);
      setTimeAutoPlay(DEFAULT_TIME_AUTO_PLAY);
      setScreen((oldScreen) => (oldScreen === TrackList.length ? 1 : oldScreen + 1));
    }, timeAutoPlay);

    return () => {
      clearInterval(action);
    };
  }, [timeAutoPlay]);

  const handleSelectScreen = (index: number) => {
    setPreventScroll(true);
    setTimeAutoPlay(TIME_AUTO_PLAY);
    setScreen(index);
  };

  const renderDesktopItems = () => (
    <div className='items'>
      <div className='vertical-line' />
      {TrackList.map((item, index) => (
        <div key={item.id} className='item' onClick={() => handleSelectScreen(index + 1)} aria-hidden>
          <div className={`icon ${item.icon}`} />
          <div className='text'>{item.text}</div>
        </div>
      ))}
    </div>
  );

  const calculatePosition = () => {
    if (!scrollListRef.current) return 0;
    const { scrollTop, clientHeight } = scrollListRef.current;
    const position = Math.round((clientHeight + scrollTop) / clientHeight);
    if (position > TrackList.length) return 0;
    return position;
  };

  const calculateScrollTo = (position: number) => {
    if (!scrollListRef.current) return 0;
    const { scrollHeight } = scrollListRef.current;
    return (scrollHeight / TrackList.length) * (position - 1);
  };

  const autoScroll = async (position: number) => {
    if (scrollListRef.current) {
      const top = calculateScrollTo(position);
      (scrollListRef.current as any).scrollTo({ top, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (scrollListRef.current) {
      autoScroll(screen);
    }
  }, [screen]);

  const onScroll = () => {
    if (preventScroll) {
      return;
    }
    const position = calculatePosition();
    if (!position || position > TrackList.length) return;
    setScreen(position);
  };

  useEffect(() => {
    const listInnerElement = scrollListRef.current;

    if (listInnerElement) {
      listInnerElement.addEventListener('scroll', onScroll);

      return () => {
        listInnerElement.removeEventListener('scroll', onScroll);
      };
    }
    return () => {};
  }, [preventScroll]);

  const renderMobileItems = () => (
    <div
      className='items-mobile'
      ref={scrollListRef}
      onTouchStart={() => {
        setPreventScroll(false);
        setTimeAutoPlay(TIME_AUTO_PLAY);
      }}
      aria-hidden
    >
      {TrackList.map((item) => (
        <span key={item.id} className='item-mobile' aria-hidden>
          <div className={`icon ${item.icon}`} />
          <div className='text'>{item.text}</div>
        </span>
      ))}
    </div>
  );

  const renderTrackList = () => (
    <>
      {renderDesktopItems()}
      {renderMobileItems()}
    </>
  );

  return (
    <Section8Wrapper>
      <div className='container'>
        <div className='title'> Stay on track with our handy App!</div>
        <div className='content'>
          <div className='left'>{renderTrackList()}</div>
          <div className='right'>
            <div className='phone-wrapper'>
              <div className='phone'>
                <div
                  className='iphone-14-pro'
                  onTouchStart={onTouchStart}
                  onTouchMove={onTouchMove}
                  onTouchEnd={onTouchEnd}
                  ref={ourRef}
                  onMouseDown={onMouseDown}
                  onMouseUp={onMouseUp}
                  onMouseMove={onMouseMove}
                  aria-hidden
                >
                  <div className='buttons'>
                    <div className='power-button' />
                    <div className='silence-button' />
                    <div className='plus-button' />
                    <div className='subtract-button' />
                  </div>
                  <div className='body-phone'>
                    <div className='screen-background' />
                    <div className='stroke-body-outside' />
                    <div className='stroke-body' />
                    <div className='screen'>
                      {TrackList.map((item, index) => (
                        <img key={item.id} className={`${index + 1 === screen ? 'active' : ''}`} src={item.img} alt='screen' />
                      ))}
                    </div>
                  </div>
                  <div className='front-camera' />
                </div>
              </div>
              <div className='pagination'>
                {TrackList.map((item, index) => (
                  <div key={item.id} className={`dot ${index + 1 === screen ? 'active' : ''}`} onClick={() => handleSelectScreen(index + 1)} aria-hidden />
                ))}
              </div>
              <div className='download'>
                <a className='app-store' href={DOWNLOAD_APP_STORE_LINK} aria-label='ios-link' target='_blank' rel='noreferrer'>
                  &nbsp;
                </a>
                <a className='google-play' href={DOWNLOAD_GOOGLE_PLAY_LINK} aria-label='android-link' target='_blank' rel='noreferrer'>
                  &nbsp;
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section8Wrapper>
  );
};
export default Section8;
