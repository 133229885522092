/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { grotesk, red, solaire, white } from '../../../../styles/theme';

export const NewHomepageWrapper = styled.section`
  h2 {
    font-size: 48px;
    font-family: ${solaire};
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.36px;
    font-family: ${grotesk};
  }
  .btn.btn-orange {
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    border-radius: 1000px;
    border: none;
    background-color: ${red};
    color: ${white};
    text-align: center;
    min-width: unset;
    width: 171px;
    font-family: ${grotesk};
    font-size: 18px;
    font-weight: 450;
    line-height: 25.2px;
    letter-spacing: 0.36px;
  }
  @media screen and (max-width: 992px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
    .btn.btn-orange {
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.28px;
      width: 150px;
      font-family: Inter;
    }
    h2 {
      font-size: 32px;
      line-height: 42px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.28px;
    }
  }
  @media screen and (min-width: 767px) {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
`;
