import React, { useEffect, useState } from 'react';
import CartReminder from './CartReminder';
import CompletedQuizToday from './CompletedQuizToday';

const HomePagePopup = (): JSX.Element => {
  const [isCartReminder, setIsCartReminder] = useState<boolean>(false);

  useEffect(() => {
    const cartReminder = localStorage.getItem('cart');
    if (cartReminder && cartReminder !== '[]') {
      setIsCartReminder(true);
    }
  });

  return isCartReminder ? <CartReminder /> : <CompletedQuizToday />;
};

export default HomePagePopup;
