/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { solaire } from '../../../../styles/theme';

export const Section3Wrapper = styled.section`
  position: relative;
  // background-color: #F9F5ED;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(253, 252, 250) 100%);
  padding-bottom: 250px;
  &:before {
    content: '';
    position: absolute;
    background-image: url(/assets/images/homepage/Frame-102.png);
    background-repeat: no-repeat;
    background-position: 100% 0;
    width: 249px;
    height: 462px;
    top: 0;
    right: 0;
  }
  &:after {
    content: '';
    position: absolute;
    background-image: url(/assets/images/homepage/Curcumin-1.png);
    background-repeat: no-repeat;
    background-position: 100% 0;
    width: 225px;
    height: 314px;
    bottom: -80px;
    left: 0;
  }
  .vector {
    height: 178px;
    background-image: url(/assets/images/homepage/Vector.png);
    background-repeat: no-repeat;
    background-color: #FDFCFA;
    background-size: 100%;
    background-position: 0 100%;
  }
  h2 {
    text-align: center;
    font-family: ${solaire};
    max-width: 450px;
    margin: auto;
    margin-top: 69px;
    position: relative;
    padding-bottom: 173px;
    img {
      position: absolute;
      top: 50px;
      right: -72px;
    }
  }
  .steps {
    padding: 0 10%;
    .step {
      margin: 0 40px;
      width: 33.333%;
      .step-img {
        display: block;
        width: 240px;
        height: 240px;
        border-radius: 50%;
        margin: auto;
        margin-bottom: 60px;
      }
      .step-content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      h3 {
        text-align: center;
        font-size: 28px;
        font-family: ${solaire};
        line-height: 30.8px;
        margin-bottom: 32px;
        max-width: 299px;
        img {
          display: block;
          margin: auto;
          margin-bottom: 32px;
        }
      }
      p {
        color: #828282;
        text-align: center;
        font-size: 16px;
        line-height: 22.4px;
        letter-spacing: 0.32px;
        max-width: 250px;
      }
    }
  }
  @media only screen and (max-width: 1359px) {
    .steps {
      padding: 0 5%;
      .step {
        margin: 0 30px;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    &:after {
      content: unset;
    }
    h2 {
      line-height: 42px;
      margin-top: 87px;
      padding-bottom: 72px;
      img {
        top: 39px;
        right: 39px;
        width: 106px;
      }
    }
    .steps {
      flex-direction: column;
      padding: 0 30%;
      .step {
        display: flex;
        width: 100%;
        margin: 0 0 60px;
        position: relative;
        .step-img {
          width: 100px;
          height: 100px;
          margin: unset;
          margin-right: 15px;
        }
        .step-content {
          align-items: start;
        }
        h3, p {
          text-align: left;
        }
        h3 {
          display: flex;
          margin-bottom: 8px;
          font-size: 20px;
          img {
            width: 30px;
            height: 30px;
            margin-right: 8px;
            margin-top: auto;
            margin-bottom: auto;
            position: absolute;
            left: 0;
            top: -25px;
          }
          br {
            display: none;
          }
        }
      }
      .step:last-child {
        margin: 0 0 30px;
      }
    }
  }
  @media only screen and (max-width: 769px) {
    padding-bottom: 117px;
    .steps {
      padding: 0 25%;
    }
  }
  @media only screen and (max-width: 450px) {
    padding-bottom: 147px;
    &:before {
      background-image: url(/assets/images/homepage/Frame-102-m.png);
      width: 94px;
      height: 192px;
      top: -18px;
    }
    .vector {
      height: 150px;
      background-image: url(/assets/images/homepage/Vector-m.png);
    }
    .steps {
      padding: 0 24px;
      .step {
        p {
          font-size: 14px;
        }
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .steps {
      padding: 0 22px;
    }
  }
`;