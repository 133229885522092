/* eslint-disable react/no-danger */
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import { multiply } from 'lodash';
import { GetStaticProps } from 'next';
import { getCountries, getSetting } from '../common/next-util';
import { pageMetaDescription, pageTitles } from '../common/const';
import { useSurvey } from '../containers/SurveyContainer';
import ModalUnfinishedQuiz from '../components/ModalUnfinishedQuiz';
import { trustPilotScript } from '../common/util';
import ModalRedoQuiz from '../components/ModalRedoQuiz';
import { useCountry } from '../containers/CountryContainer';
import { useHeader } from '../containers/HeaderContainer';
import { insiderObject } from '../common/insider';
import NewHomepage from '../components/new-homepage/NewHomepage';
import HomePagePopup from '../components/popup/HomePagePopup';

const Home = (): JSX.Element => {
  const country = useCountry((container) => container.country);
  const [trustScript, setTrustScript] = useState(false);
  const showModalUnfinishedQuiz = useSurvey((container) => container.showModalUnfinishedQuiz);
  const setShowModalUnfinishedQuiz = useSurvey((container) => container.setShowModalUnfinishedQuiz);
  const surveyPageRouter = useSurvey((container) => container.surveyPageRouter);
  const showModalRedoQuiz = useSurvey((container) => container.showModalRedoQuiz);
  const setShowModalRedoQuiz = useSurvey((container) => container.setShowModalRedoQuiz);
  const setIsShowButton = useHeader((container) => container.setIsShowButton);

  const handleScrollY = () => {
    const position = window.pageYOffset;
    if (position > 550) return setIsShowButton(true);
    return setIsShowButton(false);
  };

  const generateWidget = (idWidget: string) => {
    const element = document.getElementById(idWidget);
    if (element) {
      if (window.innerHeight + window.scrollY >= element.offsetTop) {
        const iFrame = element.getElementsByTagName('iframe')[0];
        if (!iFrame) {
          if ((window as any).Trustpilot) { // eslint-disable-line @typescript-eslint/no-explicit-any
            (window as any).Trustpilot.loadFromElement(element); // eslint-disable-line @typescript-eslint/no-explicit-any
          }
        }
      }
    }
  };

  const handleScroll = () => {
    generateWidget('trustbox');
    generateWidget('trustbox-bottom');
  };

  function getFlooredFixed(v: number, d: number) {
    // eslint-disable-next-line no-restricted-properties
    return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
  }

  const parkStartFromPrice = (): string => {
    let price = '$1';
    if (country) {
      const amount = multiply(country.exchangeRate, 1);
      if (country.country !== 'AU') {
        price = country.currencyLabelShort + getFlooredFixed(amount, 1);
      } else {
        price = country.currencyLabelShort + amount.toFixed(2);
      }
    }
    return price;
  };

  useEffect(() => {
    insiderObject({ page: 'Home' });
    setTimeout(() => {
      if (!trustScript) {
        const pilotScript = trustPilotScript();
        document.body.appendChild(pilotScript);
        setTrustScript(true);
      }
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, 6000);

    window.addEventListener('scroll', handleScrollY, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScrollY);
    };
  }, []);

  return (
    <>
      <Head>
        <title>{pageTitles.HOME_PAGE}</title>
        <meta name="description" content={pageMetaDescription.HOME_PAGE} key="description" />
      </Head>
      <NewHomepage
        surveyPageRouter={surveyPageRouter}
        startParkPrice={parkStartFromPrice()}
      />
      <ModalUnfinishedQuiz
        showModal={showModalUnfinishedQuiz}
        setShowModal={setShowModalUnfinishedQuiz}
      />
      <ModalRedoQuiz
        showModal={showModalRedoQuiz}
        setShowModal={setShowModalRedoQuiz}
        hideButtonRecommendation={false}
      />
      <HomePagePopup />
      {/* {buttonBottom} */}
    </>
  );
};
export default Home;
export const getStaticProps: GetStaticProps = async () => {
  const setting = await getSetting();
  if (!setting) {
    return {
      props: {
        setting,
      },
      revalidate: 60,
    };
  }
  const countries = await getCountries();
  return JSON.parse(
    JSON.stringify({
      props: {
        setting,
        countries,
      },
      revalidate: 60,
    }),
  );
};
