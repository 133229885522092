/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { nHaasTx65, solaire } from '../../../../styles/theme';

export const PopupWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
  margin: 20px auto;
  .wrapper {
    display: flex;
    width: 640px;
    align-items: center;
    border-radius: 12px;
    border: 0.5px solid var(--supporting-taupe, #dcd2bd);
    background: var(--supporting-off-white, #faf9f6);
    box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.15);
  }

  .image {
    display: flex;
    width: 144px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex-shrink: 0;
    align-self: stretch;
  }

  .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px 250px 250px 30px;
  }

  .content {
    display: flex;
    padding: 24px 32px 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    .text {
      justify-content: center;
      align-items: center;
      text-align: flex-start;

      color: var(--supporting-vitable-black, #272626);
      font-family: ${solaire};
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      .number {
        color: var(--accent-watermelon, #f5785a);
      }
    }

    .cta-btn {
      font-family: ${nHaasTx65};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.32px;
      cursor: pointer;
    }
  }

  .close-icon {
    position: absolute;
    top: -8px;
    right: 12px;
    cursor: pointer;
  }

  // medium devices
  @media (max-width: 786px) {
    max-width: 400px;
    width: calc(100% - 24px);

    .image {
      width: 100%;
      max-width: 128px;
    }

    .content {
      padding: 16px 32px 16px 16px;
      gap: 12px;
      .text {
        font-size: 16px;
      }

      .cta-btn {
        font-size: 14px;
      }
    }
  }

  // mobile devices
  @media (max-width: 395px) {
    .image {
      width: 100%;
      max-width: 110px;
    }

    .content {
      padding: 16px 28px 16px 16px;
      gap: 8px;
      .cta-btn {
        padding: 12px 21px;
      }
    }

    .close-icon {
      top: -12px;
      right: 6px;
    }
  }

  @media (max-width: 335px) {
    width: calc(100% - 12px);
    .image {
      max-width: 88px;
    }
    .content {
      .text {
        font-size: 14px;
      }
      .cta-btn {
        font-size: 12px;
      }
    }
  }
`;
