import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import map from 'lodash/map';
import { Buttons, Section4Wrapper } from '../../pagestyles/components/new-homepage/Section4Wrapper';

const trustPilotReviews = [
  {
    id: 1,
    title: 'Convenience at its best',
    content: 'Love having the convenience of my daily vitamins in one packet (that is biodegradable), genius! Taking the time to spell out your needs clearly means you get exactly what you are wanting without the guess work.',
    author: 'Leesa Smith',
  },
  {
    id: 2,
    title: 'Starting my journey with Vitable',
    content: "I wanted to share my positive experience with Vitable. Not only do they provide high-quality multivitamin and supplement packs that have greatly improved my health, but their app is incredibly user-friendly and has made it easy for me to manage my daily supplements.",
    author: 'Flippish Sg',
  },
  {
    id: 3,
    title: 'Wellbeing specialists in a packet...',
    content: 'I enjoy the fact that the vitamins are catered for my specific needs. I can bring a sachet of vitamins out of the house and consume it anytime on the go, without the hassle of lugging 4-5 bottles of vitamins around. Not to mention this is highly cost efficient and value for money!',
    author: 'Ravin Singh',
  },
  {
    id: 4,
    title: 'Vitable has made positive changes to my health',
    content: 'I’ve been feeling so much more energetic and happy since I started taking my vitamins and minerals. It’s easy to update my pack each month so I can trial which supplements work best for me. Recent blood test also showed normal iron level (mine’s usually low) and I know it’s thanks to the iron supplements I’ve been taking.',
    author: 'Tina Park',
  },
  {
    id: 5,
    title: 'Give it a try! Simple daily routine, tailored to your needs!',
    content: "The quick and easy 5 min quiz got my interest peaked. No more buying multiple vitamin bottles and forgetting to take them. The Vitable app tracker and handy plastic-free daily packets make it a breeze to take on the go! Vitable is a daily tailored healthy regime all done for you!",
    author: 'Reuben Cheok',
  },
  {
    id: 6,
    title: '5 stars from the very beginning...',
    content: '5 stars from the very beginning of the experience. The quiz was very thorough and gave great insights into what my body needs. My order was shipped very quickly, and within a couple of days I had already felt a difference in my energy levels and my joints were less sore after my runs.',
    author: 'Natalie Lawrence',
  },
]
const settings = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 4.5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1601,
      settings: {
        slidesToShow: 3.5,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3.1,
      },
    },
    {
      breakpoint: 1260,
      settings: {
        slidesToShow: 2.6,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2.3,
      },
    },
    {
      breakpoint: 993,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 835,
      settings: {
        slidesToShow: 1.7,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 601,
      settings: {
        slidesToShow: 1.6,
      },
    },
    {
      breakpoint: 541,
      settings: {
        slidesToShow: 1.5,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1.3,
      },
    },
    {
      breakpoint: 376,
      settings: {
        slidesToShow: 1.2,
      },
    },
    {
      breakpoint: 361,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 321,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Section4: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const arrowRef = useRef<any>();
  return (
    <Section4Wrapper>
      <h2>What others are saying</h2>
      <div
        className='trustpilot-widget'
        data-locale='en-GB'
        data-template-id='53aa8807dec7e10d38f59f32'
        data-businessunit-id='5d522e49f3ce9400013574b3'
        data-style-height='150px'
        data-style-width='100%'
        data-theme='light'
      >
        <a href='https://uk.trustpilot.com/review/vitable.com.au' target="_blank" rel="noreferrer">Excellent</a>
      </div>
      <div className='trustpilot-reviews'>
        <Slider ref={arrowRef} {...settings}>
          {map(trustPilotReviews, (item) => (
            <div className="slider-item" key={item.id}>
              <LazyLoadImage src="/assets/images/homepage/Trustpilot_ratings_5star-RGB.png" />
              <h3>{item.title}</h3>
              <p>
                {item.content}
              </p>
              <strong>{item.author}</strong>
            </div>
          ))}
        </Slider>
        <Buttons>
          <button
            type='button'
            className='prev'
            aria-label="prev"
            onClick={() => arrowRef.current.slickPrev()}
          />
          <button
            type='button'
            className='next'
            aria-label="next"
            onClick={() => arrowRef.current.slickNext()}
          />
        </Buttons>
      </div>
      <h4 className='desktop'>AS FEATURED IN</h4>
      <div className='partners'>
        <p><img className="vogue" src="/assets/images/homepage/Vogue.png" alt="" /></p>
        <p><img className="urban" src="/assets/images/homepage/Urban-list.png" alt="" /></p>
        <p><img className='body-soul' src="/assets/images/homepage/Body-soul.png" alt="" /></p>
        <p><img className='sydney-morning-herald' src="/assets/images/homepage/Sydney-morning-herald.png" alt="" /></p>
        <p><img className='insider-logo' src="/assets/images/homepage/Insider_Logo.png" alt="" /></p>
      </div>
    </Section4Wrapper>
  )
};
export default Section4;
