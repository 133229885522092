import React from 'react';
import Link from 'next/link';
import { Section5Wrapper } from '../../pagestyles/components/new-homepage/Section5Wrapper';
import { INSTAGRAM_LINK } from '../../common/const';

const Section5: React.FC = () => (
  <Section5Wrapper>
    <h2>Join the community</h2>
    <p>More than <strong>800,000+ people</strong> have taken the Vitable quiz and unlocked their personalised recommendation. Find out what&apos;s in your pack today.</p>
    <div className='desktop'>
      <div className='gallery'>
        <div className='item item1'>
          <img src="/assets/images/homepage/Frame-134.jpg" alt="" />
          <img src="/assets/images/homepage/Frame-129.jpg" alt="" />
        </div>
        <div className='item item2'>
          <img src="/assets/images/homepage/Frame-127.jpg" alt="" />
          <img src="/assets/images/homepage/Frame-130.jpg" alt="" />
        </div>
        <div className='item item3'>
          <img src="/assets/images/homepage/Frame-131.jpg" alt="" />
          <img src="/assets/images/homepage/Frame-128.jpg" alt="" />
          <img src="/assets/images/homepage/Frame-122.jpg" alt="" />
        </div>
        <div className='item item4'>
          <img src="/assets/images/homepage/Frame-124.jpg" alt="" />
          <img src="/assets/images/homepage/Frame-123.jpg" alt="" />
        </div>
        <div className='item item5'>
          <img src="/assets/images/homepage/Frame-135.jpg" alt="" />
          <img src="/assets/images/homepage/Frame-121.jpg" alt="" />
        </div>
      </div>
    </div>
    <div className='mobile'>
      <div className='gallery'>
        <div className='item item1'>
          <img src="/assets/images/homepage/Frame-134.jpg" alt="" />
          <img src="/assets/images/homepage/Frame-129.jpg" alt="" />
          <img src="/assets/images/homepage/Frame-124.jpg" alt="" />
        </div>
        <div className='item item2'>
          <img src="/assets/images/homepage/Frame-127.jpg" alt="" />
          <img src="/assets/images/homepage/Frame-130.jpg" alt="" />
          <img src="/assets/images/homepage/Frame-122.jpg" alt="" />
        </div>
        <div className='item item3'>
          <img src="/assets/images/homepage/Frame-131.jpg" alt="" />
          <img src="/assets/images/homepage/Frame-128.jpg" alt="" />
          <img src="/assets/images/homepage/Frame-121.jpg" alt="" />
        </div>
      </div>
    </div>
    <div className='follow-us'>
      <img src="/assets/images/homepage/Follow-us.png" alt="" />
      <Link href={INSTAGRAM_LINK} passHref>
        <a
          className="social-link instagram"
          href={INSTAGRAM_LINK}
          target="_blank"
          rel="noreferrer"
        >@vitablevitamins</a>
      </Link>
    </div>
  </Section5Wrapper>
);
export default Section5;
