/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { solaire } from '../../../../styles/theme';

export const Section8Wrapper = styled.section`
  background-color: #fff;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  .title {
    color: #000;
    text-align: center;
    font-size: 48px;
    font-family: ${solaire};
    padding: 128px 205px 156px 221px;
  }

  .content {
    display: flex;
    flex-direction: row;
  }

  .left {
    display: flex;
    align-items: flex-end;
    flex: 1;
    justify-content: center;
  }

  .items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 128px;
    position: relative;
    max-width: 422px;
  }

  .item-mobile {
    display: none;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 56px;
  }

  .vertical-line {
    position: absolute;
    left: 56px;
    width: 1px;
    height: 100%;
    background: #dcdcdc;
  }

  .icon {
    cursor: pointer;
  }

  .calender-icon {
    content: url('/assets/images/homepage/section8/CalendarIcon.svg');
  }

  .star-icon {
    content: url('/assets/images/homepage/section8/StarIcon.svg');
  }

  .graph-icon {
    content: url('/assets/images/homepage/section8/GraphIcon.svg');
  }

  .edit-icon {
    content: url('/assets/images/homepage/section8/EditIcon.svg');
  }

  .truck-icon {
    content: url('/assets/images/homepage/section8/TruckIcon.svg');
  }

  .text {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    cursor: pointer;
    color: #000;
    font-size: 24px;
    font-family: ${solaire};
    line-height: 31px;
  }

  .right {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
  }

  .phone-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 72px;
  }

  .phone {
    width: 327.041px;
    height: 675.503px;
    flex-shrink: 0;
    position: relative;
  }

  .iphone-14-pro {
    display: flex;
    width: 330.278px;
    height: 680px;
    padding: 2px 1.237px 2.497px 2px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .front-camera {
    position: absolute;
    content: url('/assets/images/homepage/section8/FrontCamera.png');
    top: 23px;
  }

  .buttons {
    .power-button {
      position: absolute;
      right: -4px;
      top: 200px;
      content: url('/assets/images/homepage/section8/PowerButton.png');
    }

    .silence-button {
      position: absolute;
      left: 2px;
      top: 130px;
      content: url('/assets/images/homepage/section8/SilenceButton.png');
    }

    .plus-button {
      position: absolute;
      top: 180px;
      left: 2px;
      content: url('/assets/images/homepage/section8/PlusButton.png');
    }

    .subtract-button {
      position: absolute;
      left: 2px;
      top: 250px;
      content: url('/assets/images/homepage/section8/SubtractButton.png');
    }
  }

  .notch {
    content: url('/assets/images/homepage/section8/Notch.png');
  }

  .body-phone {
    width: 321.91px;
    height: 675.378px;
    flex-shrink: 0;
    border-radius: 50.153px;
    box-shadow: 0px 2.317220449447632px 3.0587310791015625px 0px rgba(0, 0, 0, 0.04), 0px 5.38771915435791px 7.111789226531982px 0px rgba(0, 0, 0, 0.05),
      0px 9.675094604492188px 12.771125793457031px 0px rgba(0, 0, 0, 0.06), 0px 16.05707359313965px 21.195337295532227px 0px rgba(0, 0, 0, 0.08),
      0px 26.453489303588867px 34.91860580444336px 0px rgba(0, 0, 0, 0.09), 0px 46.2330322265625px 61.0275993347168px 0px rgba(0, 0, 0, 0.1),
      0px 100px 132px 0px rgba(0, 0, 0, 0.14);

    .stroke-body {
      position: absolute;
      top: 2px;
      content: url('/assets/images/homepage/section8/StrokeBody.png');
    }

    .stroke-body-outside {
      width: 321.91px;
      height: 675.378px;
      flex-shrink: 0;

      border-radius: 50.153px;
      border: 0.583px solid #1b1c16;
      background: rgba(0, 0, 0, 0);
      box-shadow: 0px 0px 0.29158487915992737px 1.457924485206604px #77796b inset;
    }

    .screen-background {
      position: absolute;
      top: 3px;
      left: 5px;
      width: 320.16px;
      height: 674.189px;
      flex-shrink: 0;

      border-radius: 48.28px;
      border: 3.791px solid #989892;
      background: #000;
      box-shadow: 0px 0px 0.29158487915992737px 4.082188606262207px #3f3f3f inset;
    }

    .screen {
      position: absolute;
      top: 14px;
      left: 15px;
      width: 298.261px;
      height: 650.537px;
      flex-shrink: 0;

      img {
        width: 298.5px;
        height: 651px;
        position: absolute;
        border-radius: 36.28px;
        transform: translate(10px, 0px);
        opacity: 0;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }

      .active {
        transform: translate(0px, 0px);
        opacity: 1;
      }
    }

    .screen.screen1 {
      background: url('/assets/images/homepage/section8/Screen1_Track.png'), lightgray 50% / cover no-repeat;
    }

    .screen.screen2 {
      background: url('/assets/images/homepage/section8/Screen2_Rewards.png'), lightgray 50% / cover no-repeat;
    }

    .screen.screen3 {
      background: url('/assets/images/homepage/section8/Screen3_Monitor.png'), lightgray 50% / cover no-repeat;
    }

    .screen.screen4 {
      background: url('/assets/images/homepage/section8/Screen4_EditPack.png'), lightgray 50% / cover no-repeat;
    }

    .screen.screen5 {
      background: url('/assets/images/homepage/section8/Screen5_Manage.png'), lightgray 50% / cover no-repeat;
    }
  }

  .download {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
  }

  .app-store {
    height: 40px;
    content: url('/assets/images/homepage/section8/AppStore.png');
  }

  .google-play {
    height: 40px;
    content: url('/assets/images/homepage/section8/GooglePlay.png');
  }

  .pagination {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #dcdcdc;
    cursor: pointer;
  }

  .dot.active {
    background-color: #000;
  }

  @media (max-width: 1000px) {
    .title {
      font-size: 32px;
      padding: 74px 36px 47px 34px;
    }

    .content {
      flex-direction: column;
      gap: 42px;
    }

    .items {
      display: none;
    }

    .items-mobile {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 17px;
      padding: 0 42px;
      text-align: center;
      max-height: 135px;
      overflow: scroll;
    }

    .item-mobile {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 17px;
      min-height: 135px;
    }

    .text {
      font-size: 18px;
    }

    .right {
      align-items: center;
    }

    .download {
      flex-direction: column;
      margin-bottom: 74px;
    }
  }
`;
