/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { grotesk, red, solaire } from '../../../../styles/theme';

export const Section1Wrapper = styled.section`
  background-color: #FDFCFA;
  .homepage-hero.hero {
    min-height: 989px;
    max-height: 1001px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      min-height: 989px;
      max-height: 1001px;
      object-fit: cover;
    }

    .hero-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;

      h2 {
        position: relative;
        display: inline-block;
        margin-bottom: 26px;
        letter-spacing: .17px;
        font-family: ${solaire};
        font-size: 32px;
        font-weight: 400;
      }
      p {
        width: 100%;
        max-width: 610px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 60px;
      }
    }
  }
  a {
    background-color: transparent;
    cursor: pointer;
  }
  .trustpilot-box {
    margin-top: -30px;
    margin-bottom: 30px;
    p {
      margin-bottom: 0 !important;
      font-family: ${grotesk};
      font-size: 12px;
      span {
        font-weight: 450;
        text-decoration: underline;
      }
    }
  }
  .homepage-logo::before {
    content: '';
    background-image: url(/assets/images/LogoStamp.svg);
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    width: 107px;
    height: 108px;
    top: -42px;
    right: -52px;
    -webkit-animation: rotation 7s infinite linear;
    -moz-animation: rotation 7s infinite linear;
    animation: rotation 7s infinite linear;
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    transform-origin: center;  
    @-moz-keyframes rotation {
      from {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -ms-transform: rotate(359deg);
        transform: rotate(359deg);
      }
    }
    @-webkit-keyframes rotation {
      from {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -ms-transform: rotate(359deg);
        transform: rotate(359deg);
      }
    }  
    @keyframes rotation {
      from {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -ms-transform: rotate(359deg);
        transform: rotate(359deg);
      }
    }
    p {
      width: 100%;
      max-width: 580px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 60px;
      font-size: 18px;
      line-height: 1.7;
    }
  }
  .browse-library {
    display: block;
    margin-top: 23px;
    text-decoration: underline;
    color: ${red};
    font-size: 18px;
  }
  .discover {
    position: absolute;
    bottom: -138px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FDFCFA 100%);
    width: 100%;
    height: 270px;
    display: flex;
    justify-content: center;
    text-align: center;
    button {
      position: absolute;
      bottom: 45px;
      width: 24px;
      height: 24px;
      padding: 0;
      border: none;
      border-left: 2px solid black;
      border-bottom: 2px solid black;
      background: transparent;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      animation: sdb04 2s infinite;
      -webkit-animation: sdb04 2s infinite;
      box-sizing: border-box;
    }
    @-webkit-keyframes sdb04 {
      0% {
        -webkit-transform: rotate(-45deg) translate(0, 0);
      }
      20% {
        -webkit-transform: rotate(-45deg) translate(-10px, 10px);
      }
      40% {
        -webkit-transform: rotate(-45deg) translate(0, 0);
      }
    }
    @keyframes sdb04 {
      0% {
        transform: rotate(-45deg) translate(0, 0);
      }
      20% {
        transform: rotate(-45deg) translate(-10px, 10px);
      }
      40% {
        transform: rotate(-45deg) translate(0, 0);
      }
    }
  }
  .discover.hide-arrow-down {
    &:after {
      background-size: 0;
    }
  }
  @media screen and (max-width: 1440px) {
    .homepage-hero.hero {
      min-height: 747px;
      img {
        min-height: 747px;
        max-height: 747px;
      }
    }
    .discover {
      height: 200px;
      bottom: -100px;
    }
  }
  @media only screen and (max-width: 992px) {
    .trustpilot-box {
      p {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .homepage-hero.hero {
      min-height: 747px;
      img {
        min-height: 747px;
        max-height: 747px;
      }
      .hero-content {
        margin-top: 30px;
        h2 {
          font-size: 28px;
          margin-top: 0;
          margin-bottom: 15px;
          line-height: 37px;
          br.mobile {
            display: none;
          }
        }
        p {
          margin-bottom: 25px;
          padding: 0 45px;
        }
      }
    }
    .homepage-logo::before {
      width: 100px;
      height: 100px;
      top: -60px;
      right: -70px;
    }
    .homepage-hero {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .trustpilot-box {
      margin-top: 0;
      margin-bottom: 25px;
    }
    .main-cta {
      margin-bottom: 120px;
    }
    .browse-library {
      margin-top: 0px;
    }
    .discover {
      background-image: url(/assets/images/homepage/ellipse-m.png);
      height: 101px;
      bottom: -66px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      &:after {
        background-image: unset;
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .homepage-hero.hero {
      min-height: 645px;
      img {
        min-height: 645px;
        max-height: 645px;
      }
      .hero-content {
        margin-top: 0px;
        h2 {
          br.mobile {
            display: block;
          }
        }
        p {
          font-size: 16px !important;
          line-height: 22.4px;
          padding: 0 0;
        }
      }
    }
    .homepage-logo::before {
      top: -60px;
      right: -45px;
      height: 85px;
      width: 85px;
    }
    .main-cta {
      margin-bottom: 28px;
    }
    .discover {
      height: 97px;
      bottom: -49px;
      background-size: contain;
      button {
        bottom: unset;
        top: -27px;
      }
    }
  }
  @media only screen and (max-width: 320px) {
    .homepage-logo::before {
      right: -27px;
    }
  }
`;
