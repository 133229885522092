/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { solaire } from '../../../../styles/theme';

export const Section6Wrapper = styled.section`
  background-color: #fcfcf9;
  position: relative;
  overflow-y: visible;
  .product-bg {
    transition: all 1s ease-in-out;
    position: absolute;
    filter: blur(30px);
  }

  .current-product {
    top: 36%;
    left: calc(50% + 185px);
    filter: blur(0px);

    img {
      width: 231px;
      transform: rotate(90deg);
    }

    @media (max-width: 1400px) {
      left: calc(50% + 100px);
    }

    @media (max-width: 1200px) {
      top: 34%;
      left: calc(50% + 30px);
    }

    @media (max-width: 1000px) {
      top: 81%;
      left: calc(50% - 120px);
    }
  }

  .hidden-product-0 {
    top: -100%;
    left: -25%;
  }

  .hidden-product-1 {
    top: -8%;
    left: 25%;
  }

  .hidden-product-2 {
    top: 20%;
    left: 42%;
  }

  .hidden-product-3 {
    top: 20%;
    left: 80%;
  }

  .hidden-product-last {
    top: 20%;
    left: 80%;
    opacity: 0;
  }

  .hidden-product-0,
  .hidden-product-1,
  .hidden-product-2,
  .hidden-product-3,
  .hidden-product-last {
    img {
      height: 196px;
      width: 231px;
      transform: rotate(90deg);
    }
  }

  .container {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 215px 0px 150px 0px;
  }

  .left {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-basis: 0;
    width: 100%;
    max-width: 600px;
  }

  .right {
    margin-left: auto;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-basis: 0;
    padding: 64px 0 0 0;
    position: relative;
  }

  .title {
    font-family: 'Solaire';
    color: #000;
    text-align: center;
    font-size: 48px;
    padding: 0 0 92px 0;

    @media (min-width: 1001px) and (max-width: 1200px) {
      padding: 0 37px 50px 37px;
    }
  }

  .highlight {
    display: inline-block;
    padding: 0px 12px;
    background-position: 44% -10px;
    background-size: 110px 101px;
    background-repeat: no-repeat;
    background-image: url(/assets/images/homepage/section6/highlight.png);
  }

  .content {
    display: flex;
    gap: 32px;
    width: 100%;
    padding: 0 32px 100px 0;
    flex-direction: column;
    flex-shrink: 0;
    p {
      color: #181818;
      text-align: center;
      font-size: 18px;
      font-family: Neue haas Grotesk;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.36px;

      @media (min-width: 1400px) {
        min-width: 600px;
      }
    }
    a {
      display: flex;
      justify-content: center;
      color: #000;
      font-size: 14px;
      font-family: Neue haas Grotesk;
      font-weight: bold;
      line-height: 25.2px;
      letter-spacing: 0.28px;
      text-decoration-line: underline;
    }
  }

  .list-item {
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    gap: 43px;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      p {
        color: #272626;
        text-align: center;
        font-size: 12px;
        font-family: Neue Haas Grotesk;
        font-weight: 500;
        text-transform: uppercase;
        max-width: 92px;
        line-height: normal;
      }
    }
  }

  .product {
    display: flex;
    width: 100%;
    margin-right: 250px;
    margin-top: 32px;
    position: relative;
    img {
      position: absolute;
      right: 0px;
      top: 60px;
      width: 231px;
      transform: rotate(90deg);
      filter: blur(0px);
    }

    .position-1 {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }

    .arrow-1 {
      position: absolute;
      top: 60px;
      right: 30px;
    }

    .position-2 {
      cursor: pointer;
      position: absolute;
      top: 150px;
      right: 200px;
    }

    .arrow-2 {
      position: absolute;
      top: 110px;
      right: 160px;
      rotate: 200deg;
    }

    .position-3 {
      cursor: pointer;
      position: absolute;
      top: 300px;
      right: 70px;
    }

    .arrow-3 {
      position: absolute;
      top: 250px;
      right: 100px;
      rotate: 130deg;
    }
  }

  .relevant-info {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 220px;
    max-height: 300px;
    gap: 16px;
    .sub-title {
      line-height: 1.2em !important;
      display: flex;
      flex-direction: row;
      padding: 0 0 0 0;
      align-self: stretch;
      color: #000;
      font-size: 20px;
      font-family: ${solaire};
      line-height: 16px;
    }

    .sub-content {
      display: flex;
      flex-direction: column;
      color: #000;
      font-size: 14px;
      font-family: ${solaire};
      line-height: 16px;
      letter-spacing: 0.28px;
    }
  }

  .pos-1 {
    right: -5%;
  }

  .pos-2 {
    left: 10%;
    top: 50%;
  }

  .pos-3 {
    right: 2%;
    top: 50%;
  }

  @media (min-width: 1000px) and (max-width: 1400px) {
    .product {
      margin-right: 10px;
    }
    .relevant-info {
      position: relative;
    }

    .pos-1 {
      top: -27%;
      right: 20%;
    }

    .pos-2,
    .pos-3 {
      top: 20%;
      left: 5%;
      right: -5%;
    }
  }

  @media (min-width: 1000px) and (max-width: 1200px) {
    .pos-1 {
      top: -23%;
    }
  }

  @media (max-width: 1000px) {
    .pos-1,
    .pos-2,
    .pos-3 {
      right: 0;
      left: 0;
      top: 0;
    }
  }

  .pagination {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 400px;
    right: -25%;
    width: 100%;
    .product-name {
      color: #717171;
      text-align: center;
      font-size: 24px;
      font-family: ${solaire};
      line-height: 52.8px;
    }

    .operation {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 36px;
      flex-direction: row;
    }

    .page-number {
      display: flex;
      text-align: center;
      font-family: ${solaire};
    }

    .active-number {
      color: #000;
      font-size: 48px;
    }

    .vertical-line {
      color: #000;
      font-size: 48px;
      padding: 0 0 8px 8px;
    }

    .total-number {
      padding: 20px 0 0 12px;
      color: #a6a6a6;
      font-size: 24px;
    }

    .prev-icon,
    .next-icon {
      cursor: pointer;
    }
  }
  .science-icon {
    content: url('/assets/images/homepage/section6/ScienceIcon.svg');
  }

  .madeinaustralia-icon {
    content: url('/assets/images/homepage/section6/Made-in-Australia.svg');
  }

  .premiumingredients-icon {
    content: url('/assets/images/homepage/section6/Premium-Ingredients.svg');
  }

  .nonGMO-icon {
    //content: url('/assets/images/homepage/section6/NonGMOIcon.svg');
    content: url('assets/images/ico-3.png');
    width: 42px;
    height: 42px;
  }

  @media (max-width: 1000px) {
    overflow: hidden;
    .hidden-product-1 {
      top: 0;
      right: 0;
    }

    .hidden-product-2 {
      top: 70%;
      left: -20%;
    }

    .hidden-product-3 {
      top: 70%;
      right: -20%;
    }

    .container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 84px 0px 400px 0px;
    }

    .right {
      justify-content: center;
      align-items: center;
      padding-bottom: 400px;
    }
    .title {
      font-size: 32px;
      line-height: 42px;
      padding: 0 32px 64px;
    }

    .content {
      padding: 0 48px 84px;
      font-size: 18px;
      line-height: 28px;
    }

    .highlight {
      padding: 0px 11px;
      background-position: 38% -11px;
      background-size: 78px 65px;
    }

    .pagination {
      position: absolute;
      top: -350px;
      right: 0;
      width: 100%;
    }
    .right {
      width: 100%;
    }

    .relevant-info {
      max-width: 1000px;
      text-align: center;
      position: absolute;
      top: 300px;

      .sub-title {
        justify-content: center;
      }

      .sub-content {
        padding: 0 32px;
      }
    }

    .product {
      width: 100%;
      top: 400px;
      margin: 0;
      left: 0;
    }

    .product-frame {
      left: calc(50% + 100px);
      position: absolute;
    }

    .list-item {
      display: flex;
      flex-wrap: wrap;
      gap: 64px;
      padding: 0 40px;
      margin-left: auto;
      margin-right: auto;
      max-width: 432px;
    }
  }
`;
