/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { solaire } from '../../../../styles/theme';

export const Section7Wrapper = styled.section`
  background-image: url('/assets/images/homepage/section7/SustainableBackground.png');
  background-repeat: round;
  background-color: #fff;
  .container {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .left {
    padding: 65px 0 0 0;
    flex: 1;
    order: 1;
    position: relative;
  }

  .right {
    flex: 2;
    order: 2;
  }

  .plastic-free-typo {
    margin-right: 0;
    margin-left: auto;
    content: url('/assets/images/homepage/section7/PlasticFreeText.svg');
  }

  .plastic-image {
    margin-right: 0;
    width: 100%;
    margin-left: auto;
    content: url('/assets/images/homepage/section7/PlasticPacks.png');
  }

  .title {
    color: #000;
    text-align: center;
    font-size: 48px;
    font-family: ${solaire};
    margin-top: 278px;
    margin-bottom: 53px;
  }

  .description {
    color: var(--supporting-vitable-black, #272626);
    text-align: center;
    font-size: 18px;
    font-family: Neue Haas Grotesk;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.36px;
    max-width: 510px;
    text-align: center;
    margin: 0 auto;
  }

  a {
    padding: 22px 0 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 14px;
    font-family: Neue Haas Grotesk;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: 0.28px;
    text-decoration-line: underline;
    margin: 0 auto;
  }

  .logo-list {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .logo-list > img {
    padding: 0px 12px 0px 12px;
  }

  .logo-list > #trace-badge-id {
    width: 280px;
    a {
      padding: 6px 0px 0px 0px;
    }
  }

  .bottom {
    display: block;
    content: url('/assets/images/homepage/section7/DecorBottomLeft.svg');
  }

  @media (max-width: 1000px) {
    .container {
      flex-direction: column;
    }

    .left {
      order: 2;
      margin: 0 auto;
    }

    .right {
      order: 1;
    }

    .title {
      margin-top: 33px;
      padding: 0 35px;
      font-size: 32px;
      margin-bottom: 48px;
    }

    .description {
      padding: 0 44px;
    }

    a {
      padding-top: 16px;
    }

    .logo-list {
      display: none;
    }

    .plastic-free-typo {
      width: 27%;
      margin-right: 44px;
      content: url('/assets/images/homepage/section7/PlasticFreeTextMobile.svg');
    }

    .plastic-image {
      margin: 0 auto;
      width: 75%;
      content: url('/assets/images/homepage/section7/PlasticPacks.png');
    }

    .bottom {
      padding: 0 0 120px 0;
      content: url('/assets/images/homepage/section7/DecorBottomRight.svg');
      margin-left: auto;
      margin-right: 0;
    }
  }

  @media (min-width: 443px) {
    .description {
      padding: 0 48px;
    }
  }

  @media (max-width: 396px) {
    .description {
      padding: 0 22px;
    }
  }

  @media (max-width: 352px) {
    .description {
      padding: 0 6px;
    }
  }

  @media (min-width: 2150px) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @media (max-width: 500px) {
    background-image: url('/assets/images/homepage/section7/SustainableBackgroundMobile.png');
  }
`;
