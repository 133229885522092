import React, { useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import { useAsyncEffect } from 'use-async-effect';
import Link from 'next/link';

import { PopupWrapper } from '../../pagestyles/components/popup/PopupWrapper';
import BottomPopup from './BottomPopup';
import Button from '../common/button/Button';

const CartReminder = (): JSX.Element => {
  const [count, setCount] = useState<number>(0);
  const [ctaBtnText, setCtaBtnText] = useState<string>('Check out now');
  const { get, response } = useFetch('/api/v1/events/count-customer-purchased');

  useAsyncEffect(async () => {
    await get();
    if (response.ok) {
      const { data } = response;
      if (data.count) {
        setCount(data.count);
      }
    }
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 336) {
      setCtaBtnText('Buy now');
    } else {
      setCtaBtnText('Check out now');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <PopupWrapper>
      <BottomPopup>
        <div className='text'>
          Join <span className='number'>{count}</span> happy customers who purchased from Vitable.
        </div>
        <Link href='/checkout/review'>
          <Button subClassName='cta-btn' aria-hidden>
            {ctaBtnText}
          </Button>
        </Link>
      </BottomPopup>
    </PopupWrapper>
  );
};

export default CartReminder;
