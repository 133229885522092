/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { solaire } from '../../../../styles/theme';

export const Section2Wrapper = styled.section`
  background: #FDFCFA;
  padding: 50px 0 0;
  .title {
    padding-top: 108px;
    padding-bottom: 68px;
    display: flex;
    align-items: center
    position: relative;
    background-image: url(/assets/images/homepage/Frame-103.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    .left {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      h2, p {
        max-width: 610px;
        text-align: center;
      }
      h2 {
        font-family: ${solaire};
        margin-bottom: 35px;
        span {
          padding-bottom: 20px;
          background-image: url(/assets/images/homepage/Group-3.png);
          background-repeat: no-repeat;
          background-position: 0px 35px;
          background-size: contain;
        }
      }
      p {
        margin-bottom: 0px;
        span {
          font-weight: 450;
        }
      }
    }
    .right {
      width: 50%;
      img {
        height: auto;
        width: auto;
      }
    }
    .vitamin {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .advantage {
    p {
      margin: 0 24px;
      font-size: 20px;
      font-family: ${solaire};
      line-height: 22px;
      text-align: center;
      img {
        width: 41px;
        height: 41px;
        display: block;
        margin: auto;
        margin-bottom: 24px;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .title {
      background-size: 210px 290px;
      .left {
        h2 {
          font-size: 40px;
        }
      }
      .right {
        img {
          width: 95%;
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .title {
      display: block;
      padding-top: 189px;
      padding-bottom: 0;
      position: relative;
      background-image: url(/assets/images/homepage/Frame-103-M.png);
      background-size: unset;
      .left {
        margin-bottom: 40px;
        padding: 0 48px;
        h2, p {
          max-width: unset;
          br {
            display: none;
          }
        }
        h2 {
          font-size: 32px;
          span {
            background-position: 0px 26px;
          }
        }
        p {
          font-size: 18px;
        }
      }
      .right {
        width: unset;
        img {
          width: 60%;
        }
      }
    }
    .advantage {
      flex-wrap: wrap;
      padding: 0 16px;
      p {
        width: 33.3333%;
        margin: 0 24px 34px 24px;
      }
    }
  }
  @media only screen and (max-width: 769px) {
    padding: 50px 0 44px;
  }
  @media only screen and (max-width: 450px) {
    padding: 0px 0 44px;
    .title {
      .left {
        p {
          line-height: 28px;
        }
      }
      .right {
        width: unset;
        img {
          width: 88%;
        }
      }
    }
  }
`;
