import React, { useState } from 'react';
import { useFetch } from 'use-http';
import { useAsyncEffect } from 'use-async-effect';
import BottomPopup from './BottomPopup';
import { PopupWrapper } from '../../pagestyles/components/popup/PopupWrapper';
import { useSurvey } from '../../containers/SurveyContainer';
import Button from '../common/button/Button';

const CompletedQuizToday = (): JSX.Element => {
  const [count, setCount] = useState<number>(0);
  const surveyPageRouter = useSurvey((container) => container.surveyPageRouter);
  const { get, response } = useFetch('/api/v1/events/count-completed-quiz-today');
  const handleClickCtaBtn = () => {
    surveyPageRouter('click-take-quiz');
  };

  useAsyncEffect(async () => {
    await get();
    if (response.ok) {
      const { data } = response;
      if (data.count) {
        setCount(data.count);
      }
    }
  }, []);
  return (
    <PopupWrapper>
      <BottomPopup>
        <div className='text'>
          <span className='number'>{count}</span> people completed the expert quiz today.
        </div>
        <Button subClassName='cta-btn' onClick={handleClickCtaBtn} aria-hidden>
          Take the quiz
        </Button>
      </BottomPopup>
    </PopupWrapper>
  );
};

export default CompletedQuizToday;
