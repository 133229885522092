/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { solaire } from '../../../../styles/theme';

export const Section5Wrapper = styled.section`
  background-color: #FDFCFA;
  padding: 149px 0 17px;
  h2, p, a {
    text-align: center;
  }
  h2 {
    position: relative;
    max-width: 400px;
    margin: auto;
    margin-bottom: 23px;
    &:after {
      content: '';
      position: absolute;
      background-image: url(/assets/images/homepage/Misc_04.png);
      background-repeat: no-repeat;
      background-size: cover;
      width: 58px;
      height: 58px;
      top: -50px;
      right: 36px;
    }
  }
  p {
    max-width: 538px;
    margin: auto;
  }
  strong {
    font-weight: 450;
  }
  .gallery {
    background-image: url(/assets/images/homepage/Vector-section5.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 92px 27px 0;
    margin-top: 85px;
    display: flex;
    justify-content: center;

    .item {
      padding: 0 6px;
      // width: calc(100% / 5);
      img {
        display: block;
        margin-bottom: 16px;
      }
      img:last-child {
        margin-bottom: 0px;
      }
    }
    .item1, .item5 {
      margin-top: 156px;
    }
    .item2, .item4 {
      margin-top: 300px;
    }
  }
  .follow-us {
    margin-top: 57px;
    img {
      display: block;
      margin: auto;
      margin-bottom: 15px;
    }
    a {
      display: block;
      font-family: ${solaire};
      font-weight: 450;
      text-decoration: underline;
    }
  }
  @media only screen and (max-width: 1366px) {
    .gallery {
      .item {
        img {
          width: 99%;
        }
      }
      .item1, .item5 {
        margin-top: 138px;
      }
      .item2, .item4 {
        margin-top: 278px;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    h2 {
      margin-bottom: 64px;
      &:after {
        width: 32px;
        height: 32px;
        top: -26px;
        right: 92px;
      }
    }
    .gallery {
      .item1, .item5 {
        margin-top: 118px;
      }
      .item2, .item4 {
        margin-top: 198px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 28px 0 17px;
    p {
      max-width: 289px;
      margin: auto;
      font-size: 18px !important;
      line-height: 28px !important;
    }
    .gallery {
      background-image: unset;
      margin-top: 146px;
      padding: 0;
      .item {
        padding: 0 3px;
        width: calc(100% / 3);
        img {
          display: block;
          margin-bottom: 9px;
        }
        img:last-child {
          margin-bottom: 0px;
        }
      }
      .item1, .item5 {
        margin-top: 80px;
      }
      .item2, .item4 {
        margin-top: 164px;
      }
    }
    .follow-us {
      margin-top: 30px;
      img {
        width: 85px;
        height: 41px;
      }
      a {
        font-size: 14px;
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .gallery {
      .item1, .item3 {
        margin-top: 0px;
      }
      .item2 {
        margin-top: 64px;
      }
    }
  }
`;